<template>
  <b-row>
    <b-col md="4">
      <b-card-normal title="Hizmet Tanımlama" :showLoading="show">
        <template v-slot:body>
          <tanimlama :kategoriler="kategoriler" @showLoading="show = $event" @refresh="handlerGetData" :updateData="updateData" />
        </template>
      </b-card-normal>
    </b-col>
    <b-col md="8" order-sm="12">
      <b-card-normal title="Hizmetler" :showLoading="show">
        <template v-slot:body>
          <list :data="kategoriler" @update="handlerUpdate($event)" @refresh="handlerGetData" />
        </template>
      </b-card-normal>
    </b-col>
  </b-row>
</template>

<script>
import BCardNormal from '@core/components/b-card-actions/BCardNormal.vue';
import Tanimlama from './component/tanimlama.vue';
import List from './component/list.vue';
import { mapGetters } from 'vuex';
export default {
  components: {
    BCardNormal,
    Tanimlama,
    List,
  },
  data: () => ({
    show: false,
    kategoriler: [],
    rows: [],
    updateData: {},
  }),
  computed: {
    ...mapGetters(['getEkstraHizmetler']),
  },
  async created() {
    await this.$store.dispatch('ekstraHizmetListele');
    this.handlerGetData();
  },
  methods: {
    async handlerGetData() {
      this.show = true;
      this.kategoriler = [];
      this.getEkstraHizmetler.forEach((el) => {
        this.kategoriler.push({ ...el });
      });
      this.show = false;
    },
    handlerUpdate(event) {
      this.updateData = event;
    },
  },
};
</script>

<style lang="scss" scoped></style>
